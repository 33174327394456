// $body-bg: #ffffff;

// $theme-colors: (
//         "primary": gray
// );

//@import "~bootstrap/scss/bootstrap";

//Customised old bootstrap 3 added from old ver. USPP with disabled font and icons
//TODO: Fix the icons

$blue:    #195ace !default;

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

* {
  font-weight: 300;
}

th, strong {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  color: #195ace;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px;
}

h1 {
  font-size: 26px;
  line-height: 15px;
  color: #195ace;
  padding: 0 0 10px 0;
}

.spinnerParent {
  overflow: hidden;
    position: relative;
    width: 100%;
}

.spinnerBG {
  background: white;
  opacity: 70%;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.spinner {
  position: absolute;
  left: 50%;
}

.table > thead {
  background-color: #fff;
  font-size: 14px;
  line-height: 15px;
  color: #29353a;
}

.navbar {
  margin-bottom: 0;
  display: flow-root;
}

.mr-2, mx-2 {
  margin-left: 35px !important;
  margin-right: 0px !important;
}

.navbar-default {
  box-shadow: none;
}

footer {
  margin: 80px auto 40px;
}

#content > * {
  margin-bottom: 40px;
}

.uspp-logo-footer {
  margin-bottom: 20px;
}

#header-link {
  font-size: 5rem;
  font-weight: normal;
  font-family: 'Merriweather', serif;
  color: #0033a0;
}

#header-link:hover,
#header-link:focus {
  text-decoration: none;
  color: #0033a0;
}

#header-text {
  border-left: 2px solid #0033a0;
  margin-left: 12px;
  padding-left: 18px;
  color: #0033a0;
  line-height: 0.9rem;
  font-weight: 400;
  font-size: 0.75rem;
  display: inline-block;
  width: 96px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

#category-menu {
  float: right;
}

#category-menu a {
  border: 1px solid #0033a0;
}

.facet-list {
  height: 160px;
  line-height: 15px;
  overflow: auto;
  box-shadow: none;
}

.query {
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 35px;
}

.btn {
  background-color: #fff;
  border-color: #195ace;
  border-radius: 0%;
}

a.btn {
  margin: 0px;
  padding: 0px;
}

.btn svg {
  color: #195ace;
  height: 38px;
  width: 38px;
  padding: 4px 12px 8px 0px;
}

.btn svg:hover,
.btn svg:focus {
  height: 38px;
  width: 38px;
  padding: 4px 12px 8px 0px;
}

.query .btn-primary:hover {
  background-color: #fff;
  border-color: #195ace;
  border-radius: 0%;
  border-left: none;
  border-radius: 0 25px 25px 0;
}

button.btn.btn-primary {
  padding: 0px;
  height: 38px;
  width: 38px;
  border-left: none;
  border-radius: 0 25px 25px 0;
}

.form-control {
  font-family: 'Roboto', sans-serif;
  border-color: #195ace;
  border-radius: 25px;
  border-right: none;
  padding: 6px 6px 6px 18px;
}

.form-control::placeholder {
  color: #195ace;
  border-color: #195ace;
  box-shadow: none;
}

.form-control:focus {
  color: #195ace;
  border-color: #195ace;
  box-shadow: none;
}

.col-code a {
  white-space:nowrap;
  min-width: 50%;
  color: #29353a;
  font-weight: bold;
}

.col-desc a {
  width: 50%;
  font-size: 12px;
  line-height: 17px;
  color: #29353a;
  font-weight: bold;
}

div.col-desc {
  width: 50%;
  font-size: 12px;
  line-height: 17px;
  color: #29353a;
  font-weight: bold;
}

.col-desc small {
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  color: #29353a;
}

.linkable {
  cursor: pointer;
}

.col-legend {
  font-weight: 500;
  width: 120px;
  text-align: left;
}

#content .breadcrumb {
  margin-bottom: 20px;
  margin-left: 0;
  background-color: #fff;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

tbody {
  max-width: 100%;
}

.table > tbody > tr > td {
  border-top: 4px solid #fff;
  font-size: 12px;
  line-height: 15px;
  color: #29353a;
}

.list-group {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.list-group-item {
  border-color: #fff;
  border-style: solid;
  background-color: #fafafa;
  padding: 0.5rem 1rem;
}

.list-group-item:hover {
  background-color: #e3eaf5;
}

.list-group-facet {
  margin: 0px;
  padding: 0px;
}

.list-group-item:first-child {
  border-radius: 0%;
}

.container {
  width: 100%;
}

.table {
  font-family: 'Roboto', sans-serif;
  width: 98%;
}

tbody tr.linkableItem {
  background-color: #fafafa;
  width: 100%;
}

tbody tr.linkableItem:hover {
  background-color: #e3eaf5;
}

.tab-content {
  width: 98%;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
}

#pages-menu {
  float: right;
  margin-bottom: 0px;
  margin-right: 15px;
  font-family: 'Montserrat', sans-serif;
}

.nav-tabs {
  font-family: 'Montserrat', sans-serif;
  border-bottom: 4px solid transparent;
  font-size: 14px;
  line-height: 15px;
  color: #06379d;
  border: none;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  font-size: 14px;
  line-height: 15px;
  color: #e3eaf5;
  background-color: #195ace;
  border-color: #195ace;
  border-radius: 0%;
}
.nav-tabs .nav-link.active:hover {
  font-size: 14px;
  line-height: 15px;
  color: #e3eaf5;
  background-color: #195ace;
  border-color: #195ace;
  border-radius: 0%;
}

.navbar-brand a,
.navbar-brand a:focus {
  border-color: #195ace;
  text-decoration: none;
  color: #195ace;
}

.navbar-brand.active a, 
.navbar-brand.active a:focus {
  color: #fff;
  background-color: #195ace;
  border-color: #195ace;
  text-decoration: none;
}

div.top-nav.justify-content-end {
  margin-left: 35%;
}

#category-menu {
  float: right;
}

#pages-menu {
  float: left;
}

a.nav-link {
  cursor: pointer;
}
.pager {
  font-family: 'Roboto', sans-serif;
}

.pager .next img {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  height: 20px;
  padding: 0px 5px;
}

.pager .previous img {
  padding: 0px 5px;
  height: 20px;
}

li.list-inline-item {
  padding: 0px 5px;
  flex-wrap: nowrap;
}

.legal-title {
  padding-bottom: 20px;
}

.media {
  padding-bottom: 20px;
}

.media-left {
  margin-top: 5px;
}

.media-body {
  padding-left: 20px;
}

.media-heading {
  line-height: 1.42857143;
  margin-top: 0%;
  font-size: large;
}

.new-header {
  color: #fff;
  font-size: 14px;
  background-image: linear-gradient(to right, #1e95d3 , #0082c7, #005e9f);
  text-align: center;
  height: 40px;
}

.header-left-icon {
  float: left;
  position: absolute;
  z-index: 999;
  font-family: 'Montserrat', sans-serif;
  
}
.header-left-icon img {
  max-height: 40px;
}

.header-left-icon a {
  color: inherit;
  text-decoration: none;
}

.header-left-icon a:hover {
  color: inherit;
  text-decoration: none;
}

.pionier-logo {
  margin: auto;
  position: absolute;
  width: 100%;
  height: 40px;
}

.pionier-logo img {
  max-height: 33px;
  margin-top: 2px;
}
@media only screen and (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .pionier-logo {
    display: none;
  }
  .hamburger-menu {
    float: right;
    max-height: 35px;
    margin-right: 5px;
  }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #195ace;
  }
  .navbar-toggler {
    border: none;
    padding: 0px;
  }

  button.mr-2.navbar-toggler {
    margin: 0%;
  }
  .navbar.navbar-dark.bg-faded {
    padding: 5px;
  }
  #pages-menu {
    display: none;
  }
  #category-menu {
    float: left;
  }
  .navbar.navbar-expand-sm {
    max-width: 60%;
    padding: 0%;
  }
  ul.navbar-nav {
    float: left;
  }
  @media only screen and (max-width: 388px) {
    .navbar.navbar-expand-sm {
      max-width: 50%;
    }
  }
}

.header {
  margin-top: 35px;
}

#category-menu li {
  margin: 5px 5px 0 5px;
}

.nav-pills li a{
  font-family: 'Roboto', sans-serif;
  padding: 3px 15px 3px 15px;
  border-radius: 25px;
  font-size: 14px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.space-between div{
  text-align: center;
  vertical-align: middle;
}

.space-between div img{
  max-height: 45px;
}

ul.navbar-nav {
  margin-top: 10px;
  font-size: 12px;
}

.badge{
  float: right;
  background-color: #195ace;
}

.d-flex {
  margin-top: 10px;
}

.pcss-date {
  font-family: 'Roboto', sans-serif;
  margin-top: 0.5rem;
}

.librus-logo {
  margin-bottom: 20px;
}
